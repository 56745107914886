/**
 * Page for the conclusion of the pre-screener.
 */

import React from 'react';
import { Link, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import { makeTopLinks, css } from '../util/pagetools';
import { localizer } from '../util/localizer';
import Nav from '../components/NavToggle';

export default function ThankYou(props) {
  const ctx = props.pageContext;
  const localized = localizer(ctx);
  const backHome = localized('/');
  const cfg = (props.location ? (props.location.state || {}) : {});
  const isBrowser = typeof window !== 'undefined';
  // Redirect to home page if page is accessed without proper parameters
  if (Object.keys(cfg).length===0) {
    if (isBrowser) navigate(backHome);
    return null;
  }
  const sitedata = cfg.sitedata;
  const toplinks = makeTopLinks(sitedata);
  const footdata = {
    ...sitedata.footer,
    toplinks,
    sitename: sitedata.sitename
  };

  return (
    <div id="page">
      <Helmet htmlAttributes={{lang: ctx.locale}}>
        <meta charSet="utf-8" />
        <title>Thank You | {sitedata.sitename}</title>
        <link rel="canonical" href={`${sitedata.siteurl}/thankyou/`} />
        <meta name="description" content={cfg.header}></meta>
      </Helmet>
      <header>
        <Nav toplinks={toplinks}
          sitename={sitedata.sitename}
          ctx={props.ctx}
          currentpath={props.currentpath}
          localizerfn={localized} />
      </header>
      <div className="layout-root">
        <div className={css('thank-you', cfg.theme)}>
          <h1 className="thank-you-h1">{cfg.header}</h1>
          {cfg.text.map((item, index) => <p key={`p-${index}`}>{item}</p>)}
          <div className="mt-160">
            <Link to={backHome} className="slide-btn btn-pary-on-white">{sitedata.homelink}</Link>
          </div>
        </div>
        <Footer src={footdata} localizerfn={localized} sitename={sitedata.sitename} />
      </div>
    </div>
  );
};
